
import { mapGetters } from 'vuex'
export default {
  fetchOnServer: false,
  data: () => ({
    active: null,
    pricezones: [],
    zones: [],
    nav: []
  }),
  async fetch() {
    try {
      await this.fetchPriceZones()

      const nav = []
      const selectorPriceZones = this.pricezones.filter(
        (item) => parseInt(item.type) !== 1
      )

      if (selectorPriceZones.length) {
        nav.push({
          icon: 'stand',
          title: 'standticket'
        })
      }

      const seatmapPriceZones = this.pricezones.filter(
        (item) => parseInt(item.type) === 1
      )

      if (seatmapPriceZones.length) {
        nav.push({
          icon: 'seat',
          title: 'seatticket'
        })
      }

      this.active = nav[0].title
      this.nav = nav
    } catch (e) {}
  },
  computed: {
    ...mapGetters({
      event: 'event/event',
      count: 'cart/count'
    }),
    selectorPriceZones() {
      return this.pricezones.filter((item) => item.type !== 1)
    }
  },
  methods: {
    async fetchPriceZones() {
      try {
        const zones = await this.$axios.$get(
          `api/event/${this.event.id}/pricezonegroups`
        )
        const pricezones = [
          ...zones.data.ungrouped,
          ...zones.data.grouped.map((item) => item.price_zones).flat()
        ]

        this.zones = zones.data
        this.pricezones = pricezones
      } catch (e) {}
    }
  }
}
